<script setup lang="ts">
import { useUserStore } from '~/store/useUserStore';

const { establishment } = useAppConfig();
const userStore = useUserStore();

const currentPhone = computed(() => userStore.user.succursalle?.phone ? userStore.user.succursalle.phone : establishment.phone)
const currentMail = computed(() => userStore.user.succursalle?.mail ? userStore.user.succursalle.mail : establishment.email)
const establishmentName = computed(() => `${ establishment.name } ${ userStore.user.succursalle ? ` - ${ userStore.user.succursalle.succursalleName }` : '' }`)
console.log(userStore.user)
</script>

<template>
    <div class="popup-contact">
        <h2>Nous contacter</h2>
        <br>
        <p>Vous rencontrez des difficultés sur {{ establishmentName }} ? Afin de répondre à vos questions, contactez-nous :</p>
        <div class="contact-cards">
            <a v-if="currentPhone" :href="`tel:${currentPhone}`" class="contact-card">
                <ElementsIcon icon="fa-solid-phone" />
                <p>Par téléphone : {{ currentPhone }}</p>
            </a>
            <a v-if="currentMail" :href="`mailto:${currentMail}`" class="contact-card">
                <ElementsIcon icon="fa-solid-envelop" />
                <p>Par mail : {{ currentMail }}</p>
            </a>
        </div>
    </div>
</template>

<style scoped>
.popup-contact h2 {
    color: var(--mainColor);
    text-align: center;
}

.popup-contact p {
    margin: 10px 0;
    color: #333;
}

.contact-cards {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
}

.contact-card {
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    gap: 10px;
    text-align: left;
    color: inherit;
    text-decoration: none;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.contact-card i {
    font-size: 24px;
    color: var(--mainColor);
}

.contact-card p {
    margin: 0;
    color: #333;
}

.contact-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

svg.iconDefault{
    color: var(--mainColor);
}

</style>